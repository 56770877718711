import React from "react"
import Helmet from "react-helmet"
import './index.scss'

const Layout = ({ props, children }) => {
  return (
    <div className="layout">
      <Helmet>
        <html lang="en" />
        <title>{ props.title }</title>

        <meta name="theme-color" content="#FFF" />
        <meta name="description" content={ props.meta } />

        <link rel="stylesheet" href="https://use.typekit.net/pxo2zfs.css" />
      </Helmet>

      {/* <CookieBanner /> */}
      {children}
    </div>
  )
}

export default Layout